import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    inject,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
    ActionType,
    EntityOperations,
    List,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { ImportApiService } from '@wdx/clmi/api-services/services';
import { OperationsFacade } from '@wdx/clmi/api-services/state';
import { FeaturesService, NavItem, WdxDestroyClass } from '@wdx/shared/utils';
import { saveAs } from 'file-saver';
import { Observable, combineLatest, filter, map, takeUntil } from 'rxjs';
import { ICON_CONTEXT_MENU } from '../../../constants/icons.constants';
import { ListFacade } from '../../../features/lists/shared/services';
import { ActionButton } from '../../../models/action-button.model';
import { ActionButtonMode } from '../../../models/action-buttons-mode.model';
import { InfoCardSearchMode } from '../../../models/info-card-search-mode.model';
import { Privilege } from '../../../models/privilege.model';
import { ModalManagementService } from '../../../services/modal-management.service';
import { FilterViewFacadeService } from '../../../shared/features/form-filters';
import * as rootReducer from '../../../state/_setup/reducers';
import { ExportsFacadeService } from '../../features/exports/services/exports-facade.service';
import { FilterQueryService } from '../../features/form-filters/shared/services/filter-query';
import { ImportFileUploaderService } from '../../features/import-file-uploader/shared/services';
import {
    ADD_OPERATIONS_TO_MENU,
    ADD_TO_LIST,
    ADD_TO_LIST_ENTITIES,
    ADD_TO_LIST_VAL,
    DOWNLOAD_VAL,
    EXPORT,
    EXPORT_VAL,
    IMPORT,
    IMPORT_EXPORT_ENTITY_FEATURE_FLAGS_MAP,
    IMPORT_VAL,
} from './hybrid-select-menu.constant';
@Component({
    selector: 'clmi-hybrid-select-menu',
    templateUrl: './hybrid-select-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HybridSelectMenuComponent
    extends WdxDestroyClass
    implements OnInit
{
    private operationsFacade = inject(OperationsFacade);
    private exportsFacadeService = inject(ExportsFacadeService);
    private importFileUploaderService = inject(ImportFileUploaderService);
    private featuresService = inject(FeaturesService);
    private importApiService = inject(ImportApiService);
    private modalManagementService = inject(ModalManagementService);
    private listFacadeService = inject(ListFacade);
    private filterQueryService = inject(FilterQueryService);
    private store$ = inject(Store<rootReducer.State>);
    private filterViewFacadeService = inject(FilterViewFacadeService);

    @Input() disableExport: boolean;
    @Input() size = 'md';
    @Input() btnClass = 'btn-outline-primary';
    @Input() dropdownMenuImportPrivilege?: Privilege;
    @Input() dropdownMenuExportPrivilege?: Privilege;

    lists$: Observable<List[]>;
    listsIsLoading$: Observable<boolean>;
    listsHasError$: Observable<boolean>;

    operations$: Observable<EntityOperations>;
    operationsIsLoading$: Observable<boolean>;
    operationsHasError$: Observable<boolean>;

    queryView$: Observable<string>;
    entityType: SystemEntity;

    readonly INFO_CARD_SEARCH_MODE = InfoCardSearchMode;
    readonly ADD_TO_LIST_ENTITIES = ADD_TO_LIST_ENTITIES;

    actionButton: ActionButton = {
        mode: ActionButtonMode.DropdownButtonMenu,
        dropdownMenu: [],
        icon: ICON_CONTEXT_MENU.icon,
    };

    ngOnInit(): void {
        if (this.dropdownMenuExportPrivilege) {
            this.actionButton.dropdownMenu.push({
                ...EXPORT,
                privilege: this.dropdownMenuExportPrivilege,
            });
        }

        if (
            Object.keys(IMPORT_EXPORT_ENTITY_FEATURE_FLAGS_MAP).some(
                (key) =>
                    this.filterQueryService?.viewType === key &&
                    this.featuresService.hasFeature(
                        IMPORT_EXPORT_ENTITY_FEATURE_FLAGS_MAP[key],
                    ),
            ) &&
            this.dropdownMenuImportPrivilege
        ) {
            IMPORT.forEach((item) => {
                this.actionButton.dropdownMenu.push({
                    ...item,
                    privilege: this.dropdownMenuImportPrivilege,
                });
            });
        }

        const entity = this.filterQueryService?.view?.entity;

        if (entity && ADD_TO_LIST_ENTITIES.includes(entity)) {
            this.actionButton.dropdownMenu.push(ADD_TO_LIST);
            this.setupLists();
        }

        if (entity && ADD_OPERATIONS_TO_MENU.includes(entity)) {
            this.operations$ = this.operationsFacade
                .getOperationsForEntity$(entity)
                .pipe(takeUntil(this.destroyed$));

            this.operationsIsLoading$ = this.operationsFacade
                .getOperationsForEntityIsLoading$(entity)
                .pipe(takeUntil(this.destroyed$));

            this.operationsHasError$ = this.operationsFacade
                .getOperationsHasError$(entity)
                .pipe(takeUntil(this.destroyed$));

            this.queryView$ = this.filterQueryService.view$.pipe(
                takeUntil(this.destroyed$),
                map((view) => btoa(JSON.stringify(view?.filter))),
            );
            this.getOperationMenuItem();
        }

        this.entityType = entity;
    }

    getOperationMenuItem() {
        combineLatest([this.operations$, this.queryView$])
            .pipe(
                takeUntil(this.destroyed$),
                filter((value) => value !== undefined),
            )
            .subscribe(([op, queryView]) => {
                if (op?.actions) {
                    op.actions.forEach((action) => {
                        const actionLabel = action.displayName?.value;

                        let actionMenu: NavItem = {
                            label: actionLabel,
                            icon: action.icon,
                        };

                        if (
                            [ActionType.Form, ActionType.NewForm].includes(
                                action.type,
                            )
                        ) {
                            actionMenu = {
                                ...actionMenu,
                                formSetup: {
                                    formId: action.formName || action.url,
                                    initialisationParams: {
                                        query: queryView,
                                        type: this.entityType,
                                    },
                                },
                            };
                        }

                        const itemExists = this.actionButton.dropdownMenu.some(
                            (menuItem) => menuItem.label === actionLabel,
                        );

                        if (!itemExists) {
                            this.actionButton.dropdownMenu.push(actionMenu);
                        }
                    });
                }
            });
    }

    onImportIconClicked(navItem: NavItem): void {
        if (navItem?.value === EXPORT_VAL && !this.disableExport) {
            this.onExport();
        }

        if (navItem?.value === IMPORT_VAL) {
            this.onImport();
        }

        if (navItem?.value === DOWNLOAD_VAL) {
            this.onDownloadTemplate();
        }

        if (navItem?.value === ADD_TO_LIST_VAL) {
            this.onOpenAddToList();
        }
    }

    onExport(): void {
        this.exportsFacadeService.doExport({
            queryType: this.filterQueryService?.viewType,
            payload: this.filterQueryService?.inBuilder
                ? this.filterQueryService?.builderQuery
                : this.filterQueryService?.query,
        });
    }

    onImport(): void {
        this.importFileUploaderService.openModal(
            this.filterQueryService?.viewType,
        );
    }

    onDownloadTemplate(): void {
        this.importApiService
            .downloadTemplate$(this.filterQueryService?.viewType)
            .subscribe((data: string) => {
                saveAs(
                    new Blob([data], { type: 'application/octet-stream' }),
                    `${this.filterQueryService?.viewType}-template.csv`,
                );
            });
    }

    onOpenAddToList(): void {
        this.modalManagementService.openModalWithId('addToList');
        this.listFacadeService.loadStandardLists();
    }

    onAddToList(list: List): void {
        this.listFacadeService.addToListWithQuery(
            list,
            this.filterQueryService.view?.entity,
            this.filterQueryService.query,
        );
    }

    setupLists(): void {
        this.listFacadeService.setMarketingLists();

        this.listsIsLoading$ =
            this.listFacadeService.marketingListsIsLoading$.pipe(
                takeUntil(this.destroyed$),
            );

        this.listsHasError$ =
            this.listFacadeService.marketingListsHasError$.pipe(
                takeUntil(this.destroyed$),
            );

        this.lists$ = this.listFacadeService.marketingLists$;
    }
}
