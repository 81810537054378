import { Pipe, PipeTransform } from '@angular/core';
import { SimpleTag } from '@wdx/clmi/api-models';
import { InfoCardData } from '../../../../models/info-card-data.model';

@Pipe({
    name: 'tagsAsInfoCardData',
    standalone: true,
})
export class TagsAsInfoCardDataPipe implements PipeTransform {
    transform(tags: SimpleTag[]): InfoCardData[] {
        return tags?.map((tag) => ({
            primaryInfo: tag.tag,
            id: tag.id,
            data: tag,
        }));
    }
}
