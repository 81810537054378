import { inject, Injectable } from '@angular/core';
import { SitemapEntry } from '@wdx/clmi/api-models';
import {
    RouteFacade,
    RouterStateAndUrl,
} from '@wdx/shared/infrastructure/state';
import { ROUTE_PREFIX, ROUTE_PREFIX_ADMINISTRATION } from '@wdx/shared/utils';
import {
    BehaviorSubject,
    combineLatest,
    filter,
    map,
    Observable,
    pairwise,
    startWith,
    take,
} from 'rxjs';
import { NavigationMenu } from '../../../models/navigation-menu.model';
import { Data } from '@angular/router';
import * as R from 'ramda';

export interface RouterConfig {
    configs: SitemapEntry[];
    isAdminArea: boolean;
    pathPrefix: string;
    path: string[];
    data: Data;
}

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private routeFacade = inject(RouteFacade);

    private _navMenu: NavigationMenu[];

    private _siteMap: BehaviorSubject<SitemapEntry[]> = new BehaviorSubject(
        undefined,
    );

    public siteMap$ = this._siteMap.asObservable();

    private _adminSiteMap: BehaviorSubject<SitemapEntry[]> =
        new BehaviorSubject(undefined);

    public adminSiteMap$ = this._adminSiteMap.asObservable();

    private _adminNavMenu: BehaviorSubject<NavigationMenu[]> =
        new BehaviorSubject(undefined);

    public adminNavMenu$ = this._adminNavMenu.asObservable();

    public get navMenu(): NavigationMenu[] {
        return this._navMenu;
    }

    public setSiteMap(siteMap: SitemapEntry[]): void {
        this._siteMap.next(siteMap);
        this._navMenu = siteMap.map((menuItem) =>
            this.createNavigationMenu(menuItem),
        );
    }

    public setAdminSiteMap(adminSiteMap: SitemapEntry[]): void {
        this._adminSiteMap.next(adminSiteMap);
        this._adminNavMenu.next(
            adminSiteMap.map((menuItem) =>
                this.createNavigationMenu(
                    menuItem,
                    null,
                    ROUTE_PREFIX_ADMINISTRATION[0],
                ),
            ),
        );
    }

    public getCurrentConfig$(): Observable<RouterConfig> {
        return combineLatest({
            adminSiteMap: this.adminSiteMap$,
            siteMap: this.siteMap$,
            routeState: this.routeFacade.getRouteState$(),
        }).pipe(
            startWith({
                adminSiteMap: [] as SitemapEntry[],
                siteMap: [] as SitemapEntry[],
                routeState: { data: {}, path: [] } as RouterStateAndUrl,
            }),
            map(({ adminSiteMap, siteMap, routeState }) => {
                const { path, data } = routeState;
                const isAdminArea = this.isAdminArea(path);
                const pathPrefix = isAdminArea
                    ? `/${path[0]}/${ROUTE_PREFIX_ADMINISTRATION[0]}`
                    : `/${path[0]}`;
                const pathParts = isAdminArea ? path.slice(2) : path.slice(1);
                const currentSiteMap = isAdminArea ? adminSiteMap : siteMap;
                const currentConfig: SitemapEntry[] = [];
                if (currentSiteMap) {
                    pathParts.forEach((part, index) => {
                        if (index === 0) {
                            currentConfig.push(
                                currentSiteMap.find(
                                    (entry) => entry.code === part,
                                ),
                            );
                        } else {
                            currentConfig.push(
                                currentConfig[index - 1]?.children?.find(
                                    (entry) => entry.code === part,
                                ),
                            );
                        }
                    });
                }
                return {
                    configs: currentConfig,
                    isAdminArea,
                    pathPrefix,
                    path,
                    data,
                };
            }),
            pairwise(),
            filter(([prev, current]) => !R.equals(prev, current)),
            map(([_, current]) => current),
        );
    }

    public isAdminArea$() {
        return this.routeFacade.getPath$().pipe(
            take(1),
            map((path) => this.isAdminArea(path)),
        );
    }

    private isAdminArea(path: string[]): boolean {
        return path[1] === ROUTE_PREFIX_ADMINISTRATION[0];
    }

    private createNavigationMenu(
        menuItem: SitemapEntry,
        parentItem?: SitemapEntry,
        basePath?: string,
    ): NavigationMenu {
        const linkPrefix = basePath
            ? [...ROUTE_PREFIX, basePath]
            : [...ROUTE_PREFIX];

        return {
            id: menuItem.code,
            pageType: menuItem.pageType,
            translationKey: menuItem?.displayName?.key,
            ...(menuItem?.icon ? { icon: menuItem?.icon } : {}),
            routerLink: parentItem
                ? [...linkPrefix, parentItem.code, menuItem.code]
                : [...linkPrefix, menuItem.code],
            cySelector: menuItem.code,
            disabledBy: [],
            ...(menuItem?.children?.length
                ? {
                      menu: menuItem?.children.map((child) =>
                          this.createNavigationMenu(child, menuItem, basePath),
                      ),
                  }
                : {}),
        };
    }
}
