import { Pipe, PipeTransform } from '@angular/core';
import { SimpleTag } from '@wdx/clmi/api-models';
import { LookupSearchEvent } from '../../../../models/lookup-options.model';

@Pipe({
    name: 'filteredTags',
    standalone: true,
})
export class FilteredTagsPipe implements PipeTransform {
    transform(tags: SimpleTag[], searchEvent: LookupSearchEvent): SimpleTag[] {
        return searchEvent
            ? tags?.filter((tag) =>
                  tag.tag
                      .toLowerCase()
                      .includes(searchEvent?.searchText?.toLowerCase()),
              )
            : tags;
    }
}
