import { Content, JSONContent, TextContent } from 'vanilla-jsoneditor';
import {
    CustomOutputHandler,
    CustomInputHandler,
} from './wdx-json-editor.component';

export const encodedStringDeserialiser: CustomInputHandler = (
    value?: Content,
): JSONContent | undefined => {
    const jsonContent = value as JSONContent;
    if (!jsonContent?.json) {
        return value as JSONContent;
    }
    if (typeof jsonContent.json === 'string') {
        let json;
        try {
            json = JSON.parse(
                jsonContent.json.replace(/^"|"$/g, '').replace(/\\"/g, '"'),
            );
        } catch (error) {
            try {
                json = JSON.parse(jsonContent.json);
            } catch (error) {
                json = jsonContent.json;
            }
        }
        return {
            json,
        };
    }
    return jsonContent;
};

export const passthroughOutputHandler: CustomOutputHandler = (
    value?: Content,
): string | undefined => {
    const textContent = value as TextContent;
    if (!textContent?.text) {
        return '';
    }
    return textContent.text;
};

export const jsonSerialiser: CustomOutputHandler = (value?: Content) => {
    return (value as TextContent)?.text
        ? JSON.parse((value as TextContent).text)
        : (value as JSONContent)?.json;
};
